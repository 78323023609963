export const possibleTypes = {
  Product: [
    'DerivativeScriptureProduct',
    'DirectScriptureProduct',
    'OtherProduct',
    '.+Product'],
  Engagement: [
    'InternshipEngagement',
    'LanguageEngagement',
    '.+Engagement'],
  Project: [
    'InternshipProject',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    '.+Project'],
  Secured: [
    'CommentList',
    'CommentThreadList',
    'ProgressReportList',
    'PromptVariantResponseList',
    'SecuredAIAssistedTranslation',
    'SecuredActor',
    'SecuredBoolean',
    'SecuredBudget',
    'SecuredCeremony',
    'SecuredDate',
    'SecuredDateNullable',
    'SecuredDateRange',
    'SecuredDateTime',
    'SecuredDateTimeNullable',
    'SecuredDegree',
    'SecuredDirectory',
    'SecuredEducationList',
    'SecuredEngagementList',
    'SecuredEngagementStatus',
    'SecuredFieldRegion',
    'SecuredFieldRegions',
    'SecuredFieldZone',
    'SecuredFile',
    'SecuredFinancialReportingType',
    'SecuredFinancialReportingTypes',
    'SecuredFirstScripture',
    'SecuredFloat',
    'SecuredFloatNullable',
    'SecuredFundingAccount',
    'SecuredInt',
    'SecuredIntNullable',
    'SecuredInternPosition',
    'SecuredInternshipEngagementList',
    'SecuredLanguage',
    'SecuredLanguageEngagementList',
    'SecuredLanguageList',
    'SecuredLanguageMilestone',
    'SecuredLanguageNullable',
    'SecuredLanguages',
    'SecuredLocation',
    'SecuredLocationList',
    'SecuredLocationType',
    'SecuredLocations',
    'SecuredMethodologies',
    'SecuredMethodology',
    'SecuredOrganization',
    'SecuredOrganizationList',
    'SecuredOrganizationReach',
    'SecuredOrganizationTypes',
    'SecuredPartner',
    'SecuredPartnerList',
    'SecuredPartnerTypes',
    'SecuredPartnership',
    'SecuredPartnershipAgreementStatus',
    'SecuredPartnershipList',
    'SecuredPartnershipsProducingMediums',
    'SecuredPeriodicReport',
    'SecuredPeriodicReportList',
    'SecuredPostList',
    'SecuredProducible',
    'SecuredProductList',
    'SecuredProductMediums',
    'SecuredProductPurposes',
    'SecuredProductSteps',
    'SecuredProgressMeasurement',
    'SecuredProgressReport',
    'SecuredProgressReportStatus',
    'SecuredProjectChangeRequestList',
    'SecuredProjectChangeRequestStatus',
    'SecuredProjectChangeRequestTypes',
    'SecuredProjectList',
    'SecuredProjectMemberList',
    'SecuredProjectStep',
    'SecuredProjectTypes',
    'SecuredPrompt',
    'SecuredReportPeriod',
    'SecuredRichText',
    'SecuredRichTextNullable',
    'SecuredRoles',
    'SecuredScriptureRanges',
    'SecuredScriptureRangesOverride',
    'SecuredString',
    'SecuredStringList',
    'SecuredStringNullable',
    'SecuredTags',
    'SecuredTimeZone',
    'SecuredTranslationProjectList',
    'SecuredUnavailabilityList',
    'SecuredUnspecifiedScripturePortion',
    'SecuredUser',
    'SecuredUserStatus',
    'Secured.+'],
  PaginatedList: [
    'CommentList',
    'CommentThreadList',
    'EducationListOutput',
    'EngagementListOutput',
    'EthnoArtListOutput',
    'FieldRegionListOutput',
    'FieldZoneListOutput',
    'FileListOutput',
    'FilmListOutput',
    'FundingAccountListOutput',
    'InternshipProjectListOutput',
    'LanguageEngagementListOutput',
    'LanguageListOutput',
    'LocationListOutput',
    'NotificationList',
    'OrganizationListOutput',
    'PartnerListOutput',
    'PartnershipListOutput',
    'PeriodicReportListOutput',
    'ProductCompletionDescriptionSuggestionsOutput',
    'ProductListOutput',
    'ProgressReportList',
    'ProgressReportMediaList',
    'ProjectListOutput',
    'PromptVariantResponseList',
    'SecuredEducationList',
    'SecuredEngagementList',
    'SecuredInternshipEngagementList',
    'SecuredLanguageEngagementList',
    'SecuredLanguageList',
    'SecuredLocationList',
    'SecuredOrganizationList',
    'SecuredPartnerList',
    'SecuredPartnershipList',
    'SecuredPartnershipsProducingMediums',
    'SecuredPeriodicReportList',
    'SecuredPostList',
    'SecuredProductList',
    'SecuredProjectChangeRequestList',
    'SecuredProjectList',
    'SecuredProjectMemberList',
    'SecuredTranslationProjectList',
    'SecuredUnavailabilityList',
    'StoryListOutput',
    'TranslationProjectListOutput',
    'UnavailabilityListOutput',
    'UserListOutput',
    '.+ListOutput', 'Secured.+List'],
  Actor: [
    'SystemAgent',
    'User',
  ],
  Changeset: [
    'ProjectChangeRequest',
  ],
  ChangesetAware: [
    'Budget',
    'BudgetRecord',
    'InternshipEngagement',
    'InternshipProject',
    'LanguageEngagement',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'Partnership',
  ],
  Commentable: [
    'InternshipEngagement',
    'InternshipProject',
    'Language',
    'LanguageEngagement',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'Partner',
    'ProgressReport',
    'User',
  ],
  FileNode: [
    'Directory',
    'File',
    'FileVersion',
  ],
  FirstScripture: [
    'ExternalFirstScripture',
    'InternalFirstScripture',
    'NoFirstScripture',
  ],
  Media: [
    'Audio',
    'Image',
    'Video',
  ],
  Notification: [
    'CommentViaMentionNotification',
    'SystemNotification',
  ],
  PeriodicReport: [
    'FinancialReport',
    'NarrativeReport',
    'ProgressReport',
  ],
  Pinnable: [
    'InternshipProject',
    'Language',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'Partner',
    'User',
  ],
  PnpExtractionResult: [
    'PnpPlanningExtractionResult',
    'PnpProgressExtractionResult',
  ],
  Postable: [
    'InternshipProject',
    'Language',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'Partner',
  ],
  Producible: [
    'DerivativeScriptureProduct',
    'DirectScriptureProduct',
    'EthnoArt',
    'Film',
    'OtherProduct',
    'Story',
  ],
  Resource: [
    'Budget',
    'BudgetRecord',
    'Ceremony',
    'Comment',
    'CommentThread',
    'CommentViaMentionNotification',
    'DerivativeScriptureProduct',
    'DirectScriptureProduct',
    'Directory',
    'Education',
    'EthnoArt',
    'FieldRegion',
    'FieldZone',
    'File',
    'FileVersion',
    'Film',
    'FinancialReport',
    'FundingAccount',
    'InternshipEngagement',
    'InternshipProject',
    'Language',
    'LanguageEngagement',
    'Location',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'NarrativeReport',
    'Organization',
    'OtherProduct',
    'Partner',
    'Partnership',
    'Post',
    'ProgressReport',
    'ProjectChangeRequest',
    'ProjectMember',
    'Story',
    'SystemNotification',
    'Unavailability',
    'User',
  ],
  SearchResult: [
    'DerivativeScriptureProduct',
    'DirectScriptureProduct',
    'EthnoArt',
    'FieldRegion',
    'FieldZone',
    'Film',
    'FinancialReport',
    'FundingAccount',
    'InternshipProject',
    'Language',
    'Location',
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
    'NarrativeReport',
    'Organization',
    'OtherProduct',
    'Partner',
    'ProgressReport',
    'Story',
    'User',
  ],
  TemporalMedia: [
    'Audio',
    'Video',
  ],
  TranslationProject: [
    'MomentumTranslationProject',
    'MultiplicationTranslationProject',
  ],
  VisualMedia: [
    'Image',
    'Video',
  ],
  WorkflowTransitionTo: [
    'WorkflowTransitionDynamicTo',
    'WorkflowTransitionStaticTo',
  ],
} as const;
